import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MoreVert } from '@mui/icons-material';
import {
  DataIssueRowFragment,
  useDeleteDataIssueMutation,
  useUpdateDataIssueMutation,
} from '../../../../generated/graphql';

export function DataIssueActions(props: { dataIssue: DataIssueRowFragment }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // update return the edited data which updates the listing automatically because of the cache
  const [updateDataIssue, updateMutationState] = useUpdateDataIssueMutation();

  const [deleteDataIssue, deleteMutationState] = useDeleteDataIssueMutation({
    variables: {
      id: props.dataIssue.id,
    },
    refetchQueries: ['DataIssues'],
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setStatus = (status: string) => {
    updateDataIssue({
      variables: {
        id: props.dataIssue.id,
        status,
        notes: props.dataIssue.notes || '',
      },
    });
    handleClose();
  };

  return (
    <div>
      <IconButton
        disabled={updateMutationState.loading || deleteMutationState.loading}
        onClick={handleClick}
        size="small"
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => setStatus('New')}>Set to New</MenuItem>
        <MenuItem onClick={() => setStatus('Ignore')}>Set to Ignore</MenuItem>
        <MenuItem onClick={() => setStatus('WIP')}>Set to WIP</MenuItem>
        <MenuItem onClick={() => setStatus('Resolved')}>Set to Resolved</MenuItem>
        <MenuItem onClick={() => deleteDataIssue()}>Delete</MenuItem>
      </Menu>
    </div>
  );
}
