import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { DataIssueRowFragment } from '../../../../generated/graphql';
import { dateTimeFormatWithSeconds } from '../../../../util/date';
import { DataIssueActions } from './DataIssueActions';

export function DataIssueTable(
  props: { data?: DataIssueRowFragment[] },
) {
  if (!props.data) {
    return null;
  }

  if (props.data.length === 0) {
    return (
      <Box p={2}>No data issues found</Box>
    );
  }

  return (
    <TableContainer>
      <Table size="small" sx={{
        '& td, & th': {
          fontSize: '0.8em',
        },
      }}>
        <TableHead>
          <TableRow>
            <TableCell>Node Type</TableCell>
            <TableCell>Node Key</TableCell>
            <TableCell>Error Type</TableCell>
            <TableCell>TopNode</TableCell>
            <TableCell>Message</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Timestamp</TableCell>
            <TableCell padding="none"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.nodeType}</TableCell>
              <TableCell>{row.key}</TableCell>
              <TableCell>{row.errorType}</TableCell>
              <TableCell>{row.topNode}</TableCell>
              <TableCell>{row.message}</TableCell>
              <TableCell><DataIssueStatus status={row.status}/></TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                {dateTimeFormatWithSeconds(row.timestamp)}
              </TableCell>
              <TableCell padding="none">
                <DataIssueActions dataIssue={row} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function DataIssueStatus(props: { status: string }) {
  switch (props.status) {
    case 'New':
      return <Chip size="small" label="New" color="error"/>;
    case 'Ignore':
      return <Chip size="small" label="Ignore" color="info"/>;
    case 'WIP':
      return <Chip size="small" label="WIP" color="warning"/>;
    case 'Resolved':
      return <Chip size="small" label="Resolved" color="success"/>;
    default:
      return <Chip size="small" label={props.status} color="default"/>;
  }
}
